// GET_PRODUCT

export const GET_FAKE_PRODUCT = "GET_FAKE_PRODUCT";

// CREATE_PRODUCT
export const CREATE_FAKE_PRODUCT = "CREATE_FAKE_PRODUCT";

// UPDATE_PRODUCT

export const UPDATE_FAKE_PRODUCT = "UPDATE_FAKE_PRODUCT";

// DELETE_PRODUCT
export const DELETE_FAKE_PRODUCT = "DELETE_FAKE_PRODUCT";

// PRODUCT_NEW_COLLECTION

export const FAKE_PRODUCT_NEW_COLLECTION = "FAKE_PRODUCT_NEW_COLLECTION";

// PRODUCT_OUT_OF_STOCK
export const FAKE_PRODUCT_OUT_OF_STOCK = "FAKE_PRODUCT_OUT_OF_STOCK";

// PRODUCT_DETAIL

export const FAKE_PRODUCT_DETAIL = "FAKE_PRODUCT_DETAIL";

// PRODUCT_REVIEW

export const FAKE_PRODUCT_REVIEW = "FAKE_PRODUCT_REVIEW";


