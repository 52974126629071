// GET_USER
export const GET_USER = "GET_USER";

// GET_USER_PROFILE

export const GET_USER_PROFILE = "GET_USER_PROFILE";

// BLOCK_UNBLOCK_USER

export const BLOCK_UNBLOCK_USER = "BLOCK_UNBLOCK_USER";

// GET_USER_ORDER

export const GET_USER_ORDER = "GET_USER_ORDER";