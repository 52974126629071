// get_ banner

export const GET_WITHDRAW = "GET_WITHDRAW";

//  create withdraw
export const CREATE_WITHDRAW = "CREATE_WITHDRAW";

// update withdraw
export const UPDATE_WITHDRAW = "UPDATE_WITHDRAW";

// delete withdraw
export const DELETE_WITHDRAW = "DELETE_WITHDRAW";
