// login
export const LOGIN_ADMIN = "LOGIN_ADMIN";

//LogOut_admin
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

// get profile
export const PROFILE_ADMIN = "PROFILE_ADMIN";

//  admin profile update
export const UPDATE_PROFILE = "UPDATE_PROFILE";

// Change Password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// Update Image Profile
export const UPDATE_IMAGE_PROFILE = "UPDATE_IMAGE_PROFILE";

//Update Password
export const UPDATE_ADMIN_PASSWORD = "UPDATE_ADMIN_PASSWORD" 

export const SIGNUP_ADMIN = "SIGNUP_ADMIN";



