// GET_SELLER
export const GET_SELLER = "GET_SELLER";
export const GET_SELLER_DROP_DOWN = "GET_SELLER_DROP_DOWN";

// CREATE_SELLER
export const CREATE_SELLER = "CREATE_SELLER";

// UPDATE_SELLER
export const UPDATE_SELLER = "UPDATE_SELLER";

// BLOCK_UNBLOCK_SELLER

export const BLOCK_UNBLOCK_SELLER = "BLOCK_UNBLOCK_SELLER";

// GET_SELLER_PROFILE

export const GET_SELLER_PROFILE = "GET_SELLER_PROFILE";

// GET_SELLER_ORDER

export const GET_SELLER_ORDER = "GET_SELLER_ORDER";

// GET_SELLER_ORDER_DETAIL
export const GET_SELLER_ORDER_DETAIL = "GET_SELLER_ORDER_DETAIL";

// GET_SELLER_PRODUCT

export const GET_SELLER_PRODUCT = "GET_SELLER_PRODUCT";

// GET_SELLER_WALLET

export const GET_SELLER_WALLET = "GET_SELLER_WALLET";
// GET_SELLER_TRANSITION

export const GET_SELLER_TRANSITION = "GET_SELLER_TRANSITION";


// GET_LIVE_SELLER
export const GET_LIVE_SELLER = "GET_LIVE_SELLER";

// GET_LIVE_SELLER_PRODUCT
export const GET_LIVE_SELLER_PRODUCT = "GET_LIVE_SELLER_PRODUCT"