
// GET_FAKE_REELS
export const GET_FAKE_REELS = "GET_FAKE_REELS";

// CREATE_FAKE_REELS
export const CREATE_FAKE_REELS = "CREATE_FAKE_REELS"

// UPDATE_FAKE_REEL
export const UPDATE_FAKE_REEL = "UPDATE_FAKE_REEL"

// DELETE_FAKE_REEEL
export const DELETE_FAKE_REELS = "DELETE_FAKE_REELS";
